import React , {useState, useEffect, useRef} from 'react';
import { pidana_hari_ini, perdata_hari_ini, ambil_data, login, alertError, cekWS } from './services';
import Swal from 'sweetalert2';
import jingle from '../audios/jingle.mp3'
import { useSpeech } from 'react-text-to-speech';

const Beranda = () =>{
	const [currentSlide1, setCurrentSlide1] = useState(0);
	const [currentSlide2, setCurrentSlide2] = useState(0);
	const [data_pidana, setDataPidana] = useState([]);
	const [data_perdata, setDataPerdata] = useState([]);
	const playjingle = new Audio(jingle);
	const [panggilan, setPanggilan] = useState([]);
	const [statusPanggil, setStatus] = useState('kosong');
	const [statLoop, setLoop] = useState(false);
	const [statExe, setStatExe] = useState(false);
    const [statWS, setStatWS] = useState(null);

	const [textVoice, setText] = useState('');
	const [userid, setUserid] = useState('');
	const [nomorperkara, setNomorperkara] = useState('');
    const ws = useRef(null);

	const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    const formattedHariIni = `${day} ${monthNames[today.getMonth()]} ${year}`;

	const [loggedIn, setLoggedin] = useState(false);
	
	const fetch_data = async() =>{
		try{
			const result = await pidana_hari_ini();
			const result2 = await perdata_hari_ini();
			
			setDataPidana(result);
			setDataPerdata(result2);
		} catch(error){
			console.error('Error fetching', error);
			alertError(error);
		}
	};

	useEffect(() => {
		const loggedIn = localStorage.getItem('loggedIn') === 'true';
        if (!loggedIn) {
			popUpLogin();
		} else {
			setLoggedin(true);
		}
		// eslint-disable-next-line
	}, []);

	//loggedin true

	useEffect(() =>{
        if(loggedIn){
            localStorage.setItem('loggedIn', true.toString());
			fetch_data()
			if (ws.current === null) {
                ws.current = new WebSocket(`wss://103.127.133.166:3000/`);
                ws.current.onopen = () => {
                    console.log('WebSocket connected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    console.log('WebSocket disconnected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error:', error);
                    setStatWS(ws.current.readyState);
                };  
            }
        }
        // eslint-disable-next-line
    }, [loggedIn]);

	//popuplogin

	const popUpLogin = () => {
        let usernameInput, passwordInput, eyePassword, i;
        let user = [];
        Swal.fire({
            title: 'Login ..',
            html: `
                    <p>Silahkan login dengan Akun SIPP</p>
                <div class="row w-auto p-1">
                    <div class="col px-3">
                        <input type="text" id="username" class="form-control mb-2" placeholder="Username">
                        <div class="input-group mb-1">
                            <input type="password" id="password" class="form-control" placeholder="Password">
                            <span id="eyepassword" class="input-group-text btn btn-outline-secondary"><i id="eye" class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            `,
            confirmButtonText: 'Login',
            confirmButtonColor: '#fd7e14',
            focusConfirm: false,
            showLoaderOnConfirm: true,
			allowOutsideClick: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                usernameInput = popup.querySelector('#username');
                passwordInput = popup.querySelector('#password');
                eyePassword = popup.querySelector('#eyepassword');
                i = popup.querySelector('#eye');
                usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                usernameInput.onchange = async() => {
                    try{
                        let result = await ambil_data(`SELECT*FROM view_data_user WHERE username = '${usernameInput.value}'`);
                        if(!result.length > 0){
                            Swal.showValidationMessage(`Username tidak ditemukan!`);
                        }else{
                            Swal.resetValidationMessage()
                            user = result[0];
                        }
                    } catch (error){
                        console.error('Error :', error);
                    }
                };
                eyePassword.onclick = (event) =>{
                    event.preventDefault();
                    if(passwordInput.getAttribute('type') === 'password'){
                        passwordInput.setAttribute('type', 'text');
                        i.classList.remove('fa-eye-slash');
                        i.classList.add('fa-eye');
                    }else{
                        passwordInput.setAttribute('type', 'password');
                        i.classList.remove('fa-eye');
                        i.classList.add('fa-eye-slash');
                    }
                    
                };
            },
            preConfirm: async() => {
                const username = usernameInput.value;
                const password = passwordInput.value;
                if (!username || !password) {
                    Swal.showValidationMessage(`Username / Password tidak boleh kosong!`);
                }else{
                    try{
                        let result = await login(password, user);
                        if(result.success){
                            setLoggedin(true);
                            const newUser = {
                                userid: user.userid,
                                fullname: user.fullname,
                                jenis: user.group_name,
                            };
                            localStorage.setItem('userIn', JSON.stringify(newUser));
                        }else{
                            Swal.showValidationMessage(`Username / Password salah!`);
                        }
                    } catch (error){
						alertError(error);
                        console.error('Error :', error);
                    }
                }
            },
        });
    };

	//logout

	const logout = () =>{
        if(loggedIn){
            setLoggedin(false);
        }
        setDataPerdata([]);
		setDataPidana([]);
		setCurrentSlide1(0);
		setCurrentSlide2(0);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userIn');
		ws.current.close();
		ws.current = null;
        popUpLogin();
    };

	//ws

	const handleWebSocketMessage = (event) => {
		const data = JSON.parse(event.data);
		switch (data.type) {
			case 'panggil':
				wsPanggil(data.perkara_id, data.ruang_sidang, data.userid);
			break;
			default:
		}
	};

	//fungsi panggil

	const wsPanggil = async(id, ruangsidang, userid) => {
		let additionalData = {
			ruang_sidang: ruangsidang,
			userid: userid
		};
		try {
			let result = await ambil_data(`
				SELECT
					vdp.perkara_id,
					vdp.nomor_perkara,
					vdp.pihak_dipublikasikan,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak1,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak2
				FROM
					view_data_perkara vdp
				LEFT JOIN
					view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
				WHERE
					vdp.perkara_id = '${id}'
					AND
					vdp.tglSidang = '${formattedDate}'
				`);
			if (result.length > 0) {
				let updatedResult = { ...result[0], ...additionalData };
				setPanggilan((prevPanggilan) => [...prevPanggilan, updatedResult]);
				setLoop(true);
			} else {
				alertError('Error ambil data perkara');
			}
		} catch(error) {
			console.error('Error fetching', error);
			alertError(error);
		}
	};

	const mulai = ()=>{
		//console.log('mulai');
		setStatus('mulai');
		Panggil();
	};

	useEffect(() =>{
		if(statLoop){
			if(panggilan.length > 0 && statusPanggil === 'kosong'){
				//console.log('satu');
				mulai();
			}else if(panggilan.length > 0 && statusPanggil === 'mulai'){
				//console.log('dua');
			}else if(panggilan.length > 0 && statusPanggil === 'lanjut'){
				//console.log('tiga');
				mulai();
			}else if(panggilan.length === 0 && statusPanggil === 'lanjut'){
				//console.log('empat');
				setStatus('kosong');
			}
		}
		setLoop(false);
		// eslint-disable-next-line
	}, [statLoop]);
	
	const Panggil = () => {
		//console.log('panggil');
		let jenis = '';
		let text = '';
		let pihak1 = '';
		let pihak2 = '';

		let publikasi = panggilan[0].pihak_dipublikasikan;
		let nomor = (panggilan[0].nomor_perkara.match(/^\d+/) ?? ['...'])[0];

		let ruang = panggilan[0].ruang_sidang;
		let userid = panggilan[0].userid;

		if (panggilan[0].pihak1 !== null) {
			let p = panggilan[0].pihak1.split(' | ');
		
			let names = p.map(nameWithTitle => {
				let name = nameWithTitle.split(/Alias|alias|als|,/i)[0].trim();
				if (name.length > 18) {
					name = name.substring(0, 18).trim();
				}
				return name;
			});
		
			let truncatedNames = names.slice(0, 2).join(' | ');
		
			if (p.length > 2) {
				pihak1 = `${truncatedNames} dkk.`;
			} else {
				pihak1 = `${truncatedNames}`;
			}
		}

		pihak1 = pihak1.toLowerCase();

		if (panggilan[0].pihak2 !== null) {
			let p = panggilan[0].pihak2.split(' | ');
		
			let names = p.map(nameWithTitle => {
				let name = nameWithTitle.split(/Alias|alias|als|,/i)[0].trim();
				if (name.length > 18) {
					name = name.substring(0, 18).trim();
				}
				return name;
			});
		
			let truncatedNames = names.slice(0, 2).join(' | ');
		
			if (p.length > 2) {
				pihak2 = `${truncatedNames} dkk.`;
			} else {
				pihak2 = `${truncatedNames}`;
			}
		}

		pihak2 = pihak2.toLowerCase();

		if(panggilan[0].nomor_perkara.includes('Pdt.P')){
			jenis = 'permohonan';
			text = `panggilan, untuk pihak atas nama ${pihak1} perkara permohonan nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
		}else if(panggilan[0].nomor_perkara.includes('Pdt')){
			jenis = 'perdata';
			if(publikasi === 'Y'){
				text = `panggilan, penggugat ${pihak1} dan tergugat ${pihak2} perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}else{
				jenis = 'perdata gugatan';
				text = `panggilan, untuk pihak penggugat dan pihak tergugat perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}
		}else if(panggilan[0].nomor_perkara.includes('Pid')){
			if(publikasi === 'Y'){
				jenis = 'pidana';
				text = `panggilan, atas nama terdakwa ${pihak2}, perkara nomor, ${nomor}, ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}else{
				jenis = 'pidana anak';
				text = `panggilan, untuk penuntut umum ${pihak1}, dan pihak perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}
		}
		setText(text);
		setUserid(userid);
		setNomorperkara(panggilan[0].nomor_perkara);
		//console.log(text);
		//console.log('akhir fungsi panggil');
		setStatExe(true);
	};

	useEffect(()=>{
		if(statExe){
			excecutePanggil();
		};
		// eslint-disable-next-line
	}, [statExe]);

	const excecutePanggil = () =>{
		//console.log('excecutePanggil');
		//console.log(textVoice);
		if(textVoice !== ''){
			let play = playjingle.play();
			if (play !== undefined){
				showPanggilan();
				play.then(() =>{
					setTimeout(()=>{
						handleStart();
					}, 2150);
				}).catch((error) =>{
					alertError('Error Sound!');
					console.error('Error sound!');
				});
			} else {
				handleStart();
				showPanggilan();
			}
		}
		//console.log('akhir excecutePanggil');
	};

	const {
		// eslint-disable-next-line
		Text,
		start
	} = useSpeech({
		text: textVoice,
		volume: 1,
		voiceURI: "Google Bahasa Indonesia",
		lang: 'id-ID',
		onStop: () => {
			try {
				setStatus('lanjut');
				setPanggilan((prevPanggilan) => {
					const newArray = prevPanggilan.slice(1);
					return newArray;
				});
				kirimStatus();
				setLoop(true);
				Swal.close();
				setText('');
				setUserid('');
				setNomorperkara('');
				setStatExe(false);
			} catch (error) {
				console.error("Error in onStop handler:", error);
				alertError(error);
			}
		}
	});
	
	const handleStart = () => {
		//console.log('handlestart');
		try {
			start();
		} catch (error) {
			console.error("Error starting speech:", error);
			alertError(error);
		}
		console.log('akhir handlestart');
	};

	const kirimStatus = () => {
		if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'selesai', userid:userid }));
		}else{
			console.error('Error gagal kirim status antrian');
			alertError('Error gagal kirim status antrian');
		}
	};

	const showPanggilan = ()=>{
		Swal.fire({
			title: 'Panggilan Sidang',
			html: `
                <p class='p-0 m-0 fs-1'> Nomor : <b>${nomorperkara}</b></p>
            `,
			width: 600,
			color: "#fd7e14",
			background: "#fff",
			allowOutsideClick: true,
			showConfirmButton: false
		  });
	};

	//pidana

    useEffect(() => {
		const paragraphs = document.querySelectorAll('#p_pidana');
		
		if (data_pidana.length > 1) {
			paragraphs.forEach(paragraph => {
				paragraph.classList.add('show');
			});
	
			const interval = setInterval(() => {
				setCurrentSlide1((prevSlide) => (prevSlide === data_pidana.length - 1 ? 0 : prevSlide + 1));
			}, 13000);
	
			const timeout = setTimeout(() => {
				paragraphs.forEach(paragraph => {
					paragraph.classList.remove('show');
				});
			}, 12000);
	
			return () => {
				clearInterval(interval);
				clearTimeout(timeout);
			};
		} else {
			paragraphs.forEach(paragraph => {
				paragraph.classList.add('show');
			});
	
			return () => {
				paragraphs.forEach(paragraph => {
					paragraph.classList.remove('show');
				});
			};
		}
	}, [data_pidana.length, currentSlide1]);

    const parts = data_pidana[currentSlide1]?.majelis_hakim_text.split(/<\/?br\s*\/?>/i) || [];
    const names = parts.map(part => {
        const nameMatch = part.match(/:\s*(.+)$/);
        if (nameMatch) {
            const formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            return formattedName.replace(/,\s*$/, '');
        }
        return '';
    });

	//perdata

	useEffect(() => {
		const paragraphs = document.querySelectorAll('#p_perdata');
		
		if (data_perdata.length > 1) {
			paragraphs.forEach(paragraph => {
				paragraph.classList.add('show');
			});
	
			const interval = setInterval(() => {
				setCurrentSlide2((prevSlide) => (prevSlide === data_perdata.length - 1 ? 0 : prevSlide + 1));
			}, 13000);
	
			const timeout = setTimeout(() => {
				paragraphs.forEach(paragraph => {
					paragraph.classList.remove('show');
				});
			}, 12000);
	
			return () => {
				clearInterval(interval);
				clearTimeout(timeout);
			};
		} else {
			paragraphs.forEach(paragraph => {
				paragraph.classList.add('show');
			});
	
			return () => {
				paragraphs.forEach(paragraph => {
					paragraph.classList.remove('show');
				});
			};
		}
	}, [data_perdata.length, currentSlide2]);

    const parts2 = data_perdata[currentSlide2]?.majelis_hakim_text.split(/<\/?br\s*\/?>/i) || [];
    const names2 = parts2.map(part => {
        const nameMatch = part.match(/:\s*(.+)$/);
        if (nameMatch) {
            const formattedName = nameMatch[1].replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            return formattedName.replace(/,\s*$/, '');
        }
        return '';
    });

    useEffect(()=>{
        if(statWS !== null){
            cekWS(statWS);
        }
        // eslint-disable-next-line
    }, [statWS]);
	
	return (
		<div>
			<div className="limiter">
				<div className="background-container">
					<div className="wrap">
                        <div className='container'>
							<div className='row text-center'>
								<div className='col'>
									<p className='p-0 m-0 fw-bold fs-2 text-black'>Jadwal Sidang Pengadilan Negeri Banyumas</p>
                                    <p className='p-0 m-0 fw-bold fs-4 text-black'>Tanggal {formattedHariIni}</p>  
								</div>
							</div>
							<div className='row text-center mt-3'>
								
								<div className='col d-flex flex-column align-items-center border-end '>
									<span className='span-judul bg-danger fs-1 mb-3'>
										Sidang Pidana
									</span>

									<div>
										Nomor Perkara
										<p id='p_pidana' className="fs-1 fw-bold text-dark fade-in-out">{data_pidana[currentSlide1]?.nomor_perkara || '...'}</p>
									</div>
									<div className='row text-center'>
										<div className='col'>
											Terdakwa
											<p id='p_pidana' className='fs-5 fw-bold text-dark fade-in-out'>
												{data_pidana[currentSlide1]?.pihak_dipublikasikan === 'T' 
													? 'Disamarkan' 
													: (
														data_pidana[currentSlide1]?.terdakwa 
															? (() => {
																const names = data_pidana[currentSlide1]?.terdakwa.split(', ');
																let firstPerson = '';
																if (names.length > 0) {
																	firstPerson = names[0];
																}
																return firstPerson + (names.length > 1 ? ', Dkk' : '');
															})()
															: '...'
													)
												}
											</p>
										</div>
										<div className='col'>
											Penuntut Umum
											<p id='p_pidana' className='fs-5 fw-bold text-dark fade-in-out'>{data_pidana[currentSlide1]?.penuntut_umum || '...'}</p>
										</div>
									</div>
									<div>
										Hakim :
                                        {names.map((name, index) => (
                                            <p id='p_pidana' key={index} className='fs-5 p-0 m-0 text-dark fw-bold fade-in-out'>
                                                {name}
                                            </p>
                                        ))}
										
										Panitera Pengganti :
										<p id='p_pidana' className='fs-5 p-0 m-0 text-dark fw-bold fade-in-out'>
											{data_pidana[currentSlide1]?.panitera_pengganti_text
												? data_pidana[currentSlide1]?.panitera_pengganti_text
												.split(':')
												.pop()
												.trim()
												.replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
											: '...'}
										</p>
									</div>

								</div>
								
								<div className='col d-flex flex-column align-items-center'>
									<span className='span-judul bg-success fs-1 mb-3'>
										Sidang Perdata
									</span>

									<div>
										Nomor Perkara
										<p id='p_perdata' className="fs-1 fw-bold text-dark fade-in-out">{data_perdata[currentSlide2]?.nomor_perkara || '...'}</p>
									</div>
									<div className='row text-center'>
										{data_perdata[currentSlide2] !== undefined && data_perdata[currentSlide2].pemohon !== null ? (
											<div className='col'>
												Pemohon
												<p id='p_perdata' className='fs-5 fw-bold text-dark fade-in-out'>
													{data_perdata[currentSlide2]?.pemohon || '...'}
												</p>
											</div>
										) : (
											<>
												<div className='col'>
													Penggugat
													<p id='p_perdata' className='fs-5 fw-bold text-dark fade-in-out'>
														{data_perdata[currentSlide2]?.pihak_dipublikasikan === 'T' 
															? 'Disamarkan' 
															: (
																data_perdata[currentSlide2]?.penggugat 
																	? (() => {
																		const names = data_perdata[currentSlide2]?.penggugat.split('| ');
																		let firstPerson = '';
																		if (names.length > 0) {
																			firstPerson = names[0];
																		}
																		return firstPerson + (names.length > 1 ? ', Dkk' : '');
																	})()
																	: '...'
															)
														}
													</p>
												</div>
												<div className='col'>
													Tergugat
													<p id='p_perdata' className='fs-5 fw-bold text-dark fade-in-out'>
														{data_perdata[currentSlide2]?.pihak_dipublikasikan === 'T' 
															? 'Disamarkan' 
															: (
																data_perdata[currentSlide2]?.tergugat 
																	? (() => {
																		const names = data_perdata[currentSlide2]?.tergugat.split('| ');
																		let firstPerson = '';
																		if (names.length > 0) {
																			firstPerson = names[0];
																		}
																		return firstPerson + (names.length > 1 ? ', Dkk' : '');
																	})()
																	: '...'
															)
														}
													</p>
												</div>
											</>
										)}
									</div>

									<div>
										Hakim :
											{names2.map((name, index) => (
												<p id='p_perdata' key={index} className='fs-5 p-0 m-0 text-dark fw-bold fade-in-out'>
													{name}
												</p>
											))}
											
										Panitera Pengganti :
										<p id='p_perdata' className='fs-5 p-0 m-0 text-dark fw-bold fade-in-out'>
											{data_perdata[currentSlide2]?.panitera_pengganti_text
												? data_perdata[currentSlide2]?.panitera_pengganti_text
												.split(':')
												.pop()
												.trim()
												.replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
											: '...'}
										</p>
									</div>

								</div>
							</div>
							<div className='row mb-3 mt-4'>								
								<div className='col text-end'>
									<button className='btn btn-outline-info btn-sm' onClick={logout}>Logout</button>
									<p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Beranda;
