import React, { useState, useRef, useEffect } from 'react';
import { ClockMurni, alertError, query_rank, ambil_data, cekWS, login, formattedDate, formattedHariIni } from './services';
import Swal from 'sweetalert2';
import jingle from '../audios/jingle.mp3'
import { useSpeech } from 'react-text-to-speech';
import { TableRank } from './table';
import { createColumnHelper } from '@tanstack/table-core';

const Rank = () =>{
    const [data, setData] = useState([]);
    const [dataTwo, setDataTwo] = useState([]);
    const [statWS, setStatWS] = useState(null);
    const ws = useRef(null);
    const [loggedIn, setLoggedin] = useState(false);
    const columnHelper = createColumnHelper();
    const tableRank = document.querySelector('#table-rank');

    //const panggilan
	const playjingle = new Audio(jingle);
	const [panggilan, setPanggilan] = useState([]);
	const [statusPanggil, setStatus] = useState('kosong');
	const [statLoop, setLoop] = useState(false);
	const [statExe, setStatExe] = useState(false);

	const [textVoice, setText] = useState('');
	const [userid, setUserid] = useState('');
	const [nomorperkara, setNomorperkara] = useState('');

    const today = new Date();
    /*const dite = new Date();
    const today = new Date(dite);
    today.setDate(dite.getDate() - 6);*/

    //interval scroll
    const tableRankRef = useRef(null);

    //data

	const fetch_data = async() =>{
		try{
			const result = await ambil_data(query_rank(formattedDate(today)));
			const order = ['1', '0'];
            const sortedResult = result.filter(item => item.status !== '2');
            const statusTwo = result.filter(item => item.status === '2');

            const sortedData = [...sortedResult].sort((a, b) => {
                return order.indexOf(a.status) - order.indexOf(b.status);
            });

            setDataTwo(statusTwo);
            setData(sortedData);
		} catch(error){
			console.error('Error fetching', error);
			alertError(error);
		}
	};

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => '#',
            accessorKey: 'no',
            cell : ({row}) =>{
                const fa = (stat) =>{
                    let icon;
                    switch(stat){
                        case '0':
                            icon = 'fa-spinner fa-spin-pulse';
                        break;
                        case '1':
                            icon = 'fa-circle-play fa-beat-fade';
                        break;
                        case '2':
                            icon = 'fa-flag-checkered fa-shake';
                        break;
                        default:
                            icon = 'fa-spinner fa-spin-pulse';
                    }
                    return icon;
                }
                return(                    
                    <div className='row align-items-center hide'>
                        <div className='col fw-bold'>
                            <i className={`fa-solid ${fa(row.original.status)}`}></i>
                        </div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('nomor_perkara', {
            id: 'nomor_perkara',
            header: () => 'Nomor Perkara',
            accessorKey: 'nomor_perkara',
            cell: ({ row }) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                }
    
                return (
                    <div className='row align-items-center'>
                        <div className='col'>
                            <div className={`${textColor} fw-bold`}>
                                {nomor}
                            </div>
                        </div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('jenis', {
            id: 'jenis',
            header: () => 'Jenis Perkara',
            accessorKey: 'jenis',
            cell: ({ row }) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                }
    
                return (
                    <div className='row align-items-center'>
                        <div className='col'>
                            <div className={`${textColor} fw-bold`}>
                                {row.original.jenis}
                            </div>
                        </div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            accessorKey: 'status',
            cell: ({ row }) => {
                const btnColor = (stat) =>{
                    let btn;
                    switch(stat){
                        case '0':
                            btn = 'btn-danger';
                        break;
                        case '1':
                            btn = 'btn-primary';
                        break;
                        case '2':
                            btn = 'btn-success';
                        break;
                        default:
                            btn = 'btn-secondary';    
                    }
                    return btn;
                }
                return (
                    <div className='row align-items-center'>
                        <div className='col'>
                            <button className={`btn ${btnColor(row.original.status)} text-light`}>
                                {row.original.ket}
                            </button>
                        </div>
                    </div>
                );
            },
        }),
        columnHelper.accessor('ruangan', {
            id: 'ruangan',
            header: () => 'Ruang',
            accessorKey: 'ruangan',
            cell: ({ row }) => {
                return (
                    <div className='row align-items-center'>
                        <div className='col'>
                            {row.original.ruangan}
                        </div>
                    </div>
                );
            },
        }),
    ];

    //useeffect pertama render

	useEffect(() => {
		const loggedIn = localStorage.getItem('loggedIn') === 'true';
        if (!loggedIn) {
			popUpLogin();
		} else {
			setLoggedin(true);
		}
		// eslint-disable-next-line
	}, []);

	//loggedin true

	useEffect(() =>{
        if(loggedIn){
            localStorage.setItem('loggedIn', true.toString());
			fetch_data()
			if (ws.current === null) {
                ws.current = new WebSocket(`wss://103.127.133.166:3000/`);
                ws.current.onopen = () => {
                    console.log('WebSocket connected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    console.log('WebSocket disconnected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error:', error);
                    setStatWS(ws.current.readyState);
                };  
            }
        }
        const scrollInterval = setInterval(() => {
            if (tableRankRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = tableRankRef.current;
                if (scrollTop + clientHeight >= scrollHeight) {
                tableRankRef.current.scrollTop = 0;
                } else {
                tableRankRef.current.scrollTop += 2;
                }
            }
        }, 20);
        return () => {
            tableRankRef.current.scrollTop = 0;
            clearInterval(scrollInterval);
        };
        // eslint-disable-next-line
    }, [loggedIn]);

    const coba = () =>{
        tableRankRef.current.scrollTop = 0;
    }

	//popuplogin

	const popUpLogin = () => {
        let usernameInput, passwordInput, eyePassword, i;
        let user = [];
        Swal.fire({
            title: 'Login ..',
            html: `
                    <p>Silahkan login dengan Akun SIPP</p>
                <div class="row w-auto p-1">
                    <div class="col px-3">
                        <input type="text" id="username" class="form-control mb-2" placeholder="Username">
                        <div class="input-group mb-1">
                            <input type="password" id="password" class="form-control" placeholder="Password">
                            <span id="eyepassword" class="input-group-text btn btn-outline-secondary"><i id="eye" class="fa fa-eye-slash" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
            `,
            confirmButtonText: 'Login',
            confirmButtonColor: '#fd7e14',
            focusConfirm: false,
            showLoaderOnConfirm: true,
			allowOutsideClick: false,
            didOpen: () => {
                const popup = Swal.getPopup();
                usernameInput = popup.querySelector('#username');
                passwordInput = popup.querySelector('#password');
                eyePassword = popup.querySelector('#eyepassword');
                i = popup.querySelector('#eye');
                usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
                usernameInput.onchange = async() => {
                    try{
                        let result = await ambil_data(`SELECT*FROM view_data_user WHERE username = '${usernameInput.value}'`);
                        if(!result.length > 0){
                            Swal.showValidationMessage(`Username tidak ditemukan!`);
                        }else{
                            Swal.resetValidationMessage()
                            user = result[0];
                        }
                    } catch (error){
                        console.error('Error :', error);
                    }
                };
                eyePassword.onclick = (event) =>{
                    event.preventDefault();
                    if(passwordInput.getAttribute('type') === 'password'){
                        passwordInput.setAttribute('type', 'text');
                        i.classList.remove('fa-eye-slash');
                        i.classList.add('fa-eye');
                    }else{
                        passwordInput.setAttribute('type', 'password');
                        i.classList.remove('fa-eye');
                        i.classList.add('fa-eye-slash');
                    }
                    
                };
            },
            preConfirm: async() => {
                const username = usernameInput.value;
                const password = passwordInput.value;
                if (!username || !password) {
                    Swal.showValidationMessage(`Username / Password tidak boleh kosong!`);
                }else{
                    try{
                        let result = await login(password, user);
                        if(result.success){
                            setLoggedin(true);
                            const newUser = {
                                userid: user.userid,
                                fullname: user.fullname,
                                jenis: user.group_name,
                            };
                            localStorage.setItem('userIn', JSON.stringify(newUser));
                        }else{
                            Swal.showValidationMessage(`Username / Password salah!`);
                        }
                    } catch (error){
						alertError(error);
                        console.error('Error :', error);
                    }
                }
            },
        });
    };

    //fungsi panggil

	const wsPanggil = async(id, ruangsidang, userid) => {
		let additionalData = {
			ruang_sidang: ruangsidang,
			userid: userid
		};
		try {
			let result = await ambil_data(`
				SELECT
					vdp.perkara_id,
					vdp.nomor_perkara,
					vdp.pihak_dipublikasikan,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Pemohon', 'Penggugat', 'Penuntut Umum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak1,
					GROUP_CONCAT(
						DISTINCT CASE
							WHEN status_perkara IN ('Tergugat', 'Terdakwa', 'Anak Berhadapan dengan Hukum') THEN vpdh.nama
							ELSE NULL
						END
						SEPARATOR ' | '
					) AS pihak2
				FROM
					view_data_perkara vdp
				LEFT JOIN
					view_data_pihak vpdh ON vdp.perkara_id = vpdh.perkara_id
				WHERE
					vdp.perkara_id = '${id}'
					AND
					vdp.tglSidang = '${formattedDate(today)}'
				`);
			if (result.length > 0) {
				let updatedResult = { ...result[0], ...additionalData };
				setPanggilan((prevPanggilan) => [...prevPanggilan, updatedResult]);
				setLoop(true);
			} else {
				alertError('Error ambil data perkara');
			}
		} catch(error) {
			console.error('Error fetching', error);
			alertError(error);
		}
	};

	const mulai = ()=>{
		//console.log('mulai');
		setStatus('mulai');
		Panggil();
	};

	useEffect(() =>{
		if(statLoop){
			if(panggilan.length > 0 && statusPanggil === 'kosong'){
				//console.log('satu');
				mulai();
			}else if(panggilan.length > 0 && statusPanggil === 'mulai'){
				//console.log('dua');
			}else if(panggilan.length > 0 && statusPanggil === 'lanjut'){
				//console.log('tiga');
				mulai();
			}else if(panggilan.length === 0 && statusPanggil === 'lanjut'){
				//console.log('empat');
				setStatus('kosong');
			}
		}
		// eslint-disable-next-line
	}, [statLoop]);
	
	const Panggil = () => {
		//console.log('panggil');
		let jenis = '';
		let text = '';
		let pihak1 = '';
		let pihak2 = '';

		let publikasi = panggilan[0].pihak_dipublikasikan;
		let nomor = (panggilan[0].nomor_perkara.match(/^\d+/) ?? ['...'])[0];

		let ruang = panggilan[0].ruang_sidang;
		let userid = panggilan[0].userid;

		if (panggilan[0].pihak1 !== null) {
			let p = panggilan[0].pihak1.split(' | ');
		
			let names = p.map(nameWithTitle => {
				let name = nameWithTitle.split(/Alias|alias|als|,/i)[0].trim();
				if (name.length > 18) {
					name = name.substring(0, 18).trim();
				}
				return name;
			});
		
			let truncatedNames = names.slice(0, 2).join(' | ');
		
			if (p.length > 2) {
				pihak1 = `${truncatedNames} dkk.`;
			} else {
				pihak1 = `${truncatedNames}`;
			}
		}

		pihak1 = pihak1.toLowerCase();

		if (panggilan[0].pihak2 !== null) {
			let p = panggilan[0].pihak2.split(' | ');
		
			let names = p.map(nameWithTitle => {
				let name = nameWithTitle.split(/Alias|alias|als|,/i)[0].trim();
				if (name.length > 18) {
					name = name.substring(0, 18).trim();
				}
				return name;
			});
		
			let truncatedNames = names.slice(0, 2).join(' | ');
		
			if (p.length > 2) {
				pihak2 = `${truncatedNames} dkk.`;
			} else {
				pihak2 = `${truncatedNames}`;
			}
		}

		pihak2 = pihak2.toLowerCase();

		if(panggilan[0].nomor_perkara.includes('Pdt.P')){
			jenis = 'permohonan';
			text = `panggilan, untuk pihak atas nama ${pihak1} perkara permohonan nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
		}else if(panggilan[0].nomor_perkara.includes('Pdt')){
			jenis = 'perdata';
			if(publikasi === 'Y'){
				text = `panggilan, penggugat ${pihak1} dan tergugat ${pihak2} perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}else{
				jenis = 'perdata gugatan';
				text = `panggilan, untuk pihak penggugat dan pihak tergugat perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}
		}else if(panggilan[0].nomor_perkara.includes('Pid')){
			if(publikasi === 'Y'){
				jenis = 'pidana';
				text = `panggilan, atas nama terdakwa ${pihak2}, perkara nomor, ${nomor}, ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}else{
				jenis = 'pidana anak';
				text = `panggilan, untuk penuntut umum ${pihak1}, dan pihak perkara nomor ${nomor} ${jenis}, harap menuju ${ruang}, sidang akan segera dimulai.`;
			}
		}
		setText(text);
		setUserid(userid);
		setNomorperkara(panggilan[0].nomor_perkara);
		//console.log(text);
		//console.log('akhir fungsi panggil');
		setStatExe(true);
	};

	useEffect(()=>{
		if(statExe){
			excecutePanggil();
		};
		// eslint-disable-next-line
	}, [statExe]);

	const excecutePanggil = () =>{
		//console.log('excecutePanggil');
		//console.log(textVoice);
		if(textVoice !== ''){
			let play = playjingle.play();
			if (play !== undefined){
				showPanggilan();
				play.then(() =>{
					setTimeout(()=>{
						handleStart();
					}, 2150);
				}).catch((error) =>{
					alertError('Error Sound!');
					console.error('Error sound!');
				});
			} else {
				handleStart();
				showPanggilan();
			}
		}
		//console.log('akhir excecutePanggil');
	};

	const {
		// eslint-disable-next-line
		Text,
		start
	} = useSpeech({
		text: textVoice,
		volume: 1,
		voiceURI: "Google Bahasa Indonesia",
		lang: 'id-ID',
		onStop: () => {
			try {
				setStatus('lanjut');
				setPanggilan((prevPanggilan) => {
					const newArray = prevPanggilan.slice(1);
					return newArray;
				});
				kirimStatus();
				setLoop(true);
				Swal.close();
				setText('');
				setUserid('');
				setNomorperkara('');
				setStatExe(false);
			} catch (error) {
				console.error("Error in onStop handler:", error);
				alertError(error);
			}
		}
	});
	
	const handleStart = () => {
		//console.log('handlestart');
		try {
			start();
            setLoop(false);
		} catch (error) {
			console.error("Error starting speech:", error);
			alertError(error);
		}
		console.log('akhir handlestart');
	};

	const kirimStatus = () => {
		if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'selesai', userid:userid }));
		}else{
			console.error('Error gagal kirim status antrian');
			alertError('Error gagal kirim status antrian');
		}
	};

	const showPanggilan = ()=>{
		Swal.fire({
			title: 'Panggilan Sidang',
			html: `
                <p class='p-0 m-0 fs-1'> Nomor : <b>${nomorperkara}</b></p>
            `,
			width: 600,
			color: "#fd7e14",
			background: "#fff",
			allowOutsideClick: true,
			showConfirmButton: false
		  });
	};

    //logout

	const logout = () =>{
        if(loggedIn){
            setLoggedin(false);
        }
        setData([]);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userIn');
		ws.current.close();
		ws.current = null;
        tableRankRef.current = null;
        popUpLogin();
    };

	//ws

	const handleWebSocketMessage = (event) => {
		const data = JSON.parse(event.data);
		switch (data.type) {
			case 'panggil':
				wsPanggil(data.perkara_id, data.ruang_sidang, data.userid);
			break;
			case 'update_status':
                tableRank.classList.toggle('show');
                setTimeout(()=>{
                    changeData();
                }, 2000);
			break;
			default:
		}
	};

    useEffect(()=>{
        if(statWS !== null){
            cekWS(statWS);
        }
        // eslint-disable-next-line
    }, [statWS]);

    //fungsi fade-in-out
    const changeData = () =>{
        fetch_data()
        tableRank.classList.toggle('show');
    };

    return(
        <>
            <div className="limiter">
                <div className="background-container">
                    <div className="wrap">
                        <div className='container-fluid vh-100 d-flex flex-column'>
                            <div className='row text-center'>
                                <div className='col'>
                                    <p className='p-0 m-0 fw-bold fs-2 text-black'>Jadwal Sidang Pengadilan Negeri Banyumas</p>
                                    <p className='p-0 m-0 fw-bold fs-4 text-black'>Tanggal {formattedHariIni(today)}</p>  
                                    <p className='p-0 m-0 fw-bold fs-4 text-black'>
                                        <ClockMurni />
                                    </p>  
                                </div>
                            </div>
                            <div className='row flex-grow-1 overflow-auto'>
                                <div className="col h-100">
                                    <div className="table-rank-wrapper h-100" ref={tableRankRef}>
                                        <div className="table-rank fade-in-out show">
                                            <TableRank columns={columns} data={data} dataTwo={dataTwo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3 mt-4'>								
								<div className='col text-end'>
									<button className='btn btn-outline-info btn-sm' onClick={()=> {coba()}}>Logout</button>
									<p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rank;