import React, { useState, useEffect, useRef } from 'react';
import { Table, TableDetail } from './table';
import Swal from 'sweetalert2';
import { ambil_data, tambah_data, edit_data, formattedDate, formattedHariIni, formattedHariIniJam, query_dua, query_tiga, query_empat, cari_data, alertError, cekWS, truncateName } from './services';
//const WebSocket = require('ws');
import Modal from 'react-bootstrap/Modal';

const Lihat = () =>{
    const [data, setData] = useState([]);
    const [dataDetail, setDataDetail] = useState([]);
    const [dataCari, setDataCari] = useState([]);
    const debounceTimeout = useRef(null);
    const ws = useRef(null);
    const [statWS, setStatWS] = useState(null);
    const today = new Date();

    const [show, setShow] = useState(false);
    const [loggedIn, setLoggedin] = useState(false);
    const [dataPengunjung, setDataP] = useState([]);

    const fetch_data = async(tgl) =>{
		try{
			const result = await ambil_data(query_dua(tgl));
			setData(result);
		} catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
	};

    useEffect(()=>{
        logout();
        const loggedIn = localStorage.getItem('loggedIn') === 'true';
        if(!loggedIn){
            handleShow();
        }else{
            handleClose();
            setLoggedin(true);
        }
        // eslint-disable-next-line
    }, [])

    const handleValidate = (val, event, input, ket) =>{
        let stat = false;
        let value = val;
        let newDiv = document.createElement('div');
        newDiv.id = `validation-message-${input}`;

        if (ket === 'wajib') {
            if (!event.isTrusted) {
                if (val === '') {
                    newDiv.className = 'invalid-feedback';
                    newDiv.innerHTML = 'Tidak boleh kosong ..';
                    let existingDiv = document.getElementById(`validation-message-${input}`);
                    if (!existingDiv) {
                        event.parentNode.insertBefore(newDiv, event.nextSibling);
                    }
                    event.classList.add('is-invalid');
                    event.focus();
                } else {
                    let existingDiv = document.getElementById(`validation-message-${input}`);
                    if (existingDiv) {
                        existingDiv.remove();
                    }
                    event.classList.remove('is-invalid');
                }
            } else {
                if (val === '') {
                    newDiv.className = 'invalid-feedback';
                    newDiv.innerHTML = 'Tidak boleh kosong ..';
                    let existingDiv = document.getElementById(`validation-message-${input}`);
                    if (!existingDiv) {
                        event.target.parentNode.insertBefore(newDiv, event.target.nextSibling);
                    }
                    event.target.classList.add('is-invalid');
                    event.target.focus();
                } else {
                    let existingDiv = document.getElementById(`validation-message-${input}`);
                    if (existingDiv) {
                        existingDiv.remove();
                    }
                    event.target.classList.remove('is-invalid');
                }
            }
        }

        switch(input){
            case 'nama':
                value = validateRegex(val, event, input, 'Huruf');
            break;
            case 'nohp':
                value = validateRegex(val, event, input, 'Angka');
            break;
            default:
        };

        let tmpdata = {...dataPengunjung};

        if(val !== '' && value !== false){
            tmpdata[input] = value;
            stat = true;
        }else{
            delete tmpdata[input];
            stat = false;
        }

        setDataP(tmpdata);
        //console.log(tmpdata);
        return stat;
    }

    const validateRegex = (val, event, input, tipe) =>{
        let regex;
        let teks;
        teks = `Hanya boleh menggunakan (${tipe} tanpa Simbol) ..`;
        switch(tipe){
            case 'Huruf':
                regex = /^[A-Za-z\s]+$/;
            break;
            case 'Angka':
                regex = /^[0-9]+$/;
            break;
            case 'Email':
                regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                teks = 'Gunakan alamat Email yang benar ..'
            break;
            default:
        }
        
        let newDiv = document.createElement('div');
        newDiv.id = `validation-message-${input}`;
    
        if (!event.isTrusted) {
            if (regex.test(val)) {
                let existingDiv = document.getElementById(`validation-message-${input}`);
                if (existingDiv) {
                    existingDiv.remove();
                }
                event.classList.remove('is-invalid');
                return val;
            } else {
                newDiv.className = 'invalid-feedback';
                newDiv.innerHTML = teks;
                let existingDiv = document.getElementById(`validation-message-${input}`);
                if (!existingDiv) {
                    event.parentNode.insertBefore(newDiv, event.nextSibling);
                }
                event.classList.add('is-invalid');
                event.focus();
                return false;
            }
        }else{
            if (regex.test(val)) {
                let existingDiv = document.getElementById(`validation-message-${input}`);
                if (existingDiv) {
                    existingDiv.remove();
                }
                event.target.classList.remove('is-invalid');
                return val;
            } else {
                newDiv.className = 'invalid-feedback';
                newDiv.innerHTML = teks;
                let existingDiv = document.getElementById(`validation-message-${input}`);
                if (!existingDiv) {
                    event.target.parentNode.insertBefore(newDiv, event.target.nextSibling);
                }
                event.target.classList.add('is-invalid');
                event.target.focus();
                return false;
            }
        }
    };

    const handleSimpan = async() => {
        const fields = [
            { field: 'nama', stat: false },
            { field: 'nohp', stat: false },
        ];
    
        fields.forEach(fieldObj => {
            const fieldElement = document.getElementById(fieldObj.field);
            const fieldValue = dataPengunjung.hasOwnProperty(fieldObj.field) ? fieldElement.value : '';
            const validate = handleValidate(fieldValue, fieldElement, fieldObj.field, 'wajib');
            
            fieldObj.stat = validate;
        });
    
        if (fields.every(fieldObj => fieldObj.stat)) {
            let tmpdata = [{...dataPengunjung}];
            tmpdata[0].tgl = formattedHariIniJam(today);
            //console.log(tmpdata);
            try{
                let result = await tambah_data('tb_pengunjung', tmpdata);
                if(result.success){
                    localStorage.setItem('userIn', JSON.stringify(tmpdata));
                    setLoggedin(true);
                    setDataP(tmpdata);
                    handleClose();
                }else{
                    console.log('Error :', result.message);
                    alertError(result.message);
                }
            } catch (error){
                console.error('Error :', error);
                alertError(error);
            }
            //console.log(tmpdata);
        } else {
            return false;
        }
    };

    useEffect(() =>{
        if(loggedIn){
            localStorage.setItem('loggedIn', true.toString());
            fetch_data(formattedDate(today));

            if (ws.current === null) {
                ws.current = new WebSocket(`wss://sw.pn-banyumas.go.id/`);
                ws.current.onopen = () => {
                    console.log('WebSocket connected');
                    setStatWS(ws.current.readyState);
                };
                ws.current.onmessage = handleWebSocketMessage;
                ws.current.onclose = () => {
                    console.log('WebSocket disconnected');
                };
                ws.current.onerror = (error) => {
                    console.log('WebSocket error:', error);
                    setStatWS(ws.current.readyState);
                };
                
            }
        }
        // eslint-disable-next-line
    }, [loggedIn]);

    const handleWebSocketMessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type) {
			case 'update':
				fetch_data();
			break;
            default:
        }
    };

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(()=>{
        if(statWS !== null){
            cekWS(statWS);
        }
        // eslint-disable-next-line
    }, [statWS]);

    const columns = [
        { //nomor
            header: 'Nomor',
            accessorKey: 'no',
            cell : ({row}) =>{
                return(                    
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>No.</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.index + 1}
                        </div>
                    </div>
                );
            }
        },
        { //nomor_perkara
            header: 'Nomor Perkara',
            accessorKey: 'nomor_perkara',
            width: 100,
            cell: ({row}) => {
                const nomor = row.original.nomor_perkara;
                let textColor = 'kosong';
    
                if (nomor.includes('Pid')) {
                    textColor = 'nomor_pidana';
                } else if (nomor.includes('Pdt.P')) {
                    textColor = 'nomor_permo';
                } else if (nomor.includes('Pdt')) {
                    textColor = 'nomor_perdata';
                }
    
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Nomor Perkara</p>
                        </div>
                        <div className='col col-lg-auto'>
                            <div className={`${textColor}`}>
                                {nomor}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        { //agenda
            header: 'Agenda',
            accessorKey: 'agenda',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Agenda</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.original.agenda}
                        </div>
                    </div>
                );
            }
        },
        { //pihakpertama
            header: 'Pihak Pertama',
            accessorKey: 'pihak1',
            cell: ({ row }) => {
                const pihak1_hadir = row.original.pihak1_hadir ? row.original.pihak1_hadir.split('|') : [];
                const pihak1_tdk_hadir = row.original.pihak1_tdk_hadir ? row.original.pihak1_tdk_hadir.split('|') : [];
                const pihak1 = [...pihak1_hadir, ...pihak1_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 1</p>
                        </div>
                        <div className='col col-lg-auto'>

                            {pihak1.map((pihak, index) => {
                                const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : pihak;
                                const buttonColor = pihak1_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                                const hasil_detail = row.original.hasOwnProperty('hasil_detail') && row.original.hasil_detail !== '' ? true : false;
                                
                                if (hasil_detail && row.original.tglSidang.trim() === formattedDate(today)){
                                    return (
                                        <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(row.original.perkara_id, pihakLabel, buttonColor)}>
                                            {truncateName(pihakLabel)}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <p key={index} className='p-0 m-0 text-black'>
                                            - {pihakLabel}
                                        </p>
                                    );
                                }
                            })}

                        </div>
                    </div>
                );
            }
        },
        { //pihakkedua
            header: 'Pihak Kedua',
            accessorKey: 'pihak2',
            cell: ({ row }) => {
                const pihak2_hadir = row.original.pihak2_hadir ? row.original.pihak2_hadir.split('|') : [];
                const pihak2_tdk_hadir = row.original.pihak2_tdk_hadir ? row.original.pihak2_tdk_hadir.split('|') : [];
                const pihak2 = [...pihak2_hadir, ...pihak2_tdk_hadir];
        
                return (
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Pihak 2</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {pihak2.map((pihak, index) => {
                                const pihakLabel = row.original.pihak_dipublikasikan === 'T' ? 'Disamarkan' : pihak;
                                const buttonColor = pihak2_hadir.includes(pihak) ? 'btn-success' : 'btn-danger';
                                const hasil_detail = row.original.hasOwnProperty('hasil_detail') && row.original.hasil_detail !== '' ? true : false;
                
                                if (hasil_detail && row.original.tglSidang.trim() === formattedDate(today)){
                                    return (
                                        <button className={`btn ${buttonColor} m-2`} key={index} onClick={() => handlePihakClick(row.original.perkara_id, pihakLabel, buttonColor)}>
                                            {truncateName(pihakLabel)}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <p key={index} className='p-0 m-0 text-black'>
                                            - {pihakLabel}
                                        </p>
                                    );
                                }
                            })}
                        </div>
                    </div>
                );
            }
        },
        { //jenis
            header: 'Keterangan',
            accessorKey: 'jenis_perkara_nama',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-3 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Keterangan</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.original.jenis_perkara_nama}
                        </div>
                    </div>
                );
            }
        },
        {
            header: 'Aksi',
            accessorKey: 'aksi',
            cell: ({ row }) => {
                return (
                <div className="d-grid gap-2">
                    <button className="btn btn-primary" type="button" onClick={() => handleLihat(row.original.perkara_id)}>
                        Lihat Detail <i className="fa fa-circle-info"></i>
                    </button>
                </div>
                );
            }
        },
    ];

    const handlePihakClick = (perkaraId, pihak, buttonColor) => {
        let title = '';
        let icon = '';
        let confirmtext = '';
        let kehadiran = '';
        if (buttonColor === 'btn-danger') {
            title = 'Konfirmasi Kehadiran';
            icon = 'question';
            confirmtext = 'Ya, sudah hadir';
            kehadiran = 'Y';
        }else{
            title = 'Ubah Kehadiran';
            icon = 'warning';
            confirmtext = 'Tidak, tidak hadir';
            kehadiran = 'T';
        }

        Swal.fire({
            title: `${title}`,
            text: `Kehadiran atas nama : ${pihak}`,
            icon: `${icon}`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${confirmtext}`,
            cancelButtonText: 'Batal'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let hasil = await ambil_data(`
                        SELECT
                            pihak_id,
                            perkara_id,
                            status_perkara,
                            nama,
                            '${kehadiran}' AS kehadiran,
                            '${formattedDate(today)}' AS tglSidang
                        FROM
                            view_data_pihak vdph
                        WHERE
                            (
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                            )
                    `);

                    const fungsi_ubah = async() =>{
                        let id = await ambil_data(`
                            SELECT
                                id
                            FROM
                                data_kehadiran
                            WHERE
                            (
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                                AND tglSidang = '${formattedDate(today)}'
                            )
                        `);
                        ubah_hadir(id[0]['id'], hasil);
                    }

                    if(buttonColor === 'btn-danger'){
                        let cek = await ambil_data(`
                            SELECT
                                *
                            FROM
                                data_kehadiran
                            WHERE(
                                perkara_id = ${perkaraId}
                                AND
                                nama LIKE '%${pihak}%'
                                AND tglSidang = '${formattedDate(today)}'
                            )
                        `);
                        if(cek.length > 0){
                            fungsi_ubah();
                        }else{
                            konfirmasi_hadir(hasil)
                        }
                    }else{
                        fungsi_ubah();
                    }
                } catch (error) {
                    console.error('Error', error);
                    alertError(error);
                }
            }
        });
    };

    const konfirmasi_hadir = async(data) =>{
        try{
            let result = await tambah_data('data_kehadiran', data);
            if(result.success){
                fetch_data(formattedDate(today));
                update();
            }else{
                console.log('Error :', result.message);
                alertError(result.message);
            }
        } catch (error){
            console.error('Error :', error);
            alertError(error);
        }
    };

    const ubah_hadir = async(id, data) =>{
        try {
            let result = await edit_data(id, data);
            if(result && result.success){
                fetch_data(formattedDate(today));
                update();
            } else {
                console.log('Error :', result.message);
                alertError(result.message);
            }
        } catch (error) {
            console.error('Error :', error);
            alertError(error);
        }
    };

    const update = () =>{
        if (ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(JSON.stringify({ type: 'update' }));
		} else {
			console.error('Error gagal kirim status update');
            alertError('Error gagal kirim status update');
		}
    };

    const handleLihat = async(id) =>{
        let div = document.querySelector('#divriwayat');
        let input = document.querySelector('#inputcari');
        if(id === '00000'){
            return false;
        }
		try{
			let resultPerkara = await ambil_data(query_empat(id, formattedDate(today)));
            let resultRiwayat = await ambil_data(query_tiga(id));
			setData(resultPerkara);
            setDataDetail(resultRiwayat);
            div.classList.remove('hidethis');
		} catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
        setDataCari([]);
        input.value = '';
        input.blur();
    };

    const columnsDetail = [
        { //nomor
            header: 'Nomor',
            accessorKey: 'no',
            cell : ({row}) =>{
                return(                    
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>No.</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.index + 1}
                        </div>
                    </div>
                );
            }
        },
        { //agenda
            header: 'Agenda',
            accessorKey: 'agenda',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Agenda</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {row.original.agenda}
                        </div>
                    </div>
                );
            }
        },
        { //tglSidang
            header: 'Tanggal Sidang',
            accessorKey: 'tglSidang',
            cell : ({row}) =>{
                return(
                    <div className='row align-items-center'>
                        <div className='col col-2 th-side'>
                            <p className='text-start p-0 m-0 fs-9'>Tanggal</p>
                        </div>
                        <div className='col col-lg-auto'>
                            {formattedHariIni(row.original.tglSidang)}
                        </div>
                    </div>
                );
            }
        },
    ];

    const handleCari = (event) =>{
        let value = event.target.value;

        let span = document.querySelector('#spanspiner');
        let i = document.querySelector('#ispiner');
        let div = document.querySelector('#datalist');

        if(value !== ''){
            span.classList.remove('hidethis');
            i.classList.add('fa-spin');
            div.classList.remove('show');
            
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = setTimeout(() => {
                cari(value);
            }, 2500);
        }
    };

    const cari = async(value) =>{
        try{
            let result = await cari_data(value);
            setDataCari(result);
        } catch(error){
			console.error('Error fetching', error);
            alertError(error);
		}
    };

    const logout = () =>{
        if(loggedIn){
            setLoggedin(false);
        }
        setData([]);
        setDataCari([]);
        setDataDetail([]);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userIn');
        if(ws.current !== null){
            ws.current.close();
        }
        ws.current = null;
    };

    useEffect(()=>{
        let q;
        let i = document.querySelector('#ispiner');
        let div = document.querySelector('#datalist');
        if(dataCari.length > 0){
            i.classList.remove('fa-spin');
            div.classList.add('show');
        }else{
            if(dataCari.data === 'kosong'){
                q = [
                    {
                        perkara_id: "00000",
                        nomor_perkara: "Data tidak bisa ditemukan ..",
                        pihak1: "-",
                        pihak2: "-",
                        jenis_perkara_nama: "-",
                    }
                ]
                setDataCari(q);
                i.classList.remove('fa-spin');
                div.classList.remove('show');
            }
        }
    }, [dataCari]);

    const handleStar = () =>{
        let ketInput;
        let starReview = 0
        Swal.fire({
            title : 'Rating aplikasi ini ..',
            html : `
            <div class="swal-star">
                <div class="rating-box">
                    <div class="stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    
                    <div class="mt-3">
                        <textarea id="ketreview" class="form-control" aria-describedby="ketreview" rows="3" placeholder='Keterangan ..'></textarea>
                    </div>
                </div>
            </div>
            `,
            didOpen: () =>{
                const stars = document.querySelectorAll(".stars i");
                stars.forEach((star, index1) => {
                    star.addEventListener("click", () => {
                        stars.forEach((star, index2) => {
                            index1 >= index2 ? star.classList.add("active") : star.classList.remove("active");
                        });
                        starReview = index1 + 1;
                    });
                });

                const popup = Swal.getPopup();
                ketInput = popup.querySelector('#ketreview');
                ketInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
            },
            preConfirm: async()=>{
                const user = JSON.parse(localStorage.getItem('userIn'));
                const keterangan = ketInput.value;
                try{
                    const result = await ambil_data(`SELECT*FROM tb_pengunjung WHERE nama = '${user[0].nama}' AND nohp = '${user[0].nohp}';`);
                    if(result.length > 0){
                        const edit = await edit_data('tb_pengunjung', result[0].id, [{ bintang: starReview, ket: keterangan}]);
                        if(edit.success){
                            console.log('Sukses');
                        }else{
                            console.log('Error review:', edit.message);
                            alertError(edit.message);
                        }
                    }
                } catch(error){
                    console.error('Error fetching', error);
                    alertError(error);
                }
            },
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: "Batal",
            confirmButtonText: "Simpan"
        });
    };

    return(
        <div>
			<div className="limiter">
				<div className="background-container">
					<div className="wrap">
                        <div className='container'>
                            <div className='row text-center'>
								<div className='col'>
                                    <button onClick={() => window.location.reload()} className='btn btn-primary btn-refresh m-2'><i className='fa fa-refresh'></i></button>
									<p className='p-0 m-0 fw-bold fs-2 text-black'>Jadwal Sidang Pengadilan Negeri Banyumas</p>
                                    <p className='p-0 m-0 fw-bold fs-4 text-black'>Tanggal {formattedHariIni(today)}</p>
                                    <div className='col d-flex flex-column align-items-center'>
                                        <div className="form-text fs-xsmall">Cari Berdasarkan Nomor perkara / Nama pihak / Tanggal sidang ..</div>
                                        <div className="input-group mt-2 w-75">                                            
                                            <span className="input-group-text"><i className='fa fa-search'></i></span>
                                            <input id='inputcari' onChange={handleCari} type="text" className="form-control" />
                                            <span id='spanspiner' className="input-group-text hidethis"><i id='ispiner' className='fa fa-spinner'></i></span>
                                        </div>
                                        <div id='datalist' className='w-75 position-relative'>
                                            <div className='w-100 ps-5 fs-xsmall text-start position-absolute'>
                                                {Object.entries(dataCari).map(([key, value]) => (
                                                    <span key={key} onClick={()=>handleLihat(value.perkara_id)} className='d-block p-1'><b>{value.nomor_perkara} | {value.pihak1} | {value.pihak2} | {value.jenis_perkara_nama}</b></span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2 p-3'>
                                        <Table columns={columns} data={data} />
                                    </div>
                                    <div id='divriwayat' className='hidethis'>
                                        <p className='p-0 m-0 fw-bold fs-5 text-black'>Riwayat Persidangan</p>  
                                        <div className='row mt-3 p-3'>
                                            <TableDetail columns={columnsDetail} data={dataDetail} />
                                        </div>
                                    </div>
								</div>
							</div>
                            <div className='row mb-3'>								
								<div className='col text-end'>
                                    <button onClick={handleStar} className='btn btn-warning btn-star m-2'><i className='fa fa-star'></i></button>
									<p className='copy_right p-0 m-0 text-dark'>Copyright @sijenggo86</p>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
            
                <Modal className='inimodal' show={show} backdrop="static" keyboard={false} centered>
                    <Modal.Body>
                        <div className='container'>
							<div className='row text-center'>
								<div className='col'>
									<p className='p-0 m-0 fw-bold fs-3'><i>e - Tamu</i></p>
                                    <p className='p-0 m-0 fs-7'>Pengunjung Sidang</p>  
                                    <p className='p-0 m-0 fs-6 mb-3'>Pengadilan Negeri Banyumas</p>  
								</div>
							</div>
                            <div className='row'>
                                <div className='col'>
                                    <form>
                                        <div className="mb-2">
                                            <input onChange={(event) => handleValidate(event.target.value, event, 'nama', 'wajib')} id="nama" type="nama" className="form-control" aria-describedby="nama" placeholder='Isi Namamu Disini ..' />
                                        </div>
                                        <div className="mb-2">
                                            <textarea onChange={(event) => handleValidate(event.target.value, event, 'alamat', 'wajib')} id="alamat" className="form-control" aria-describedby="alamat" rows="3" placeholder='Isi Alamatmu disini ..'></textarea>
                                        </div>
                                        <div className="mb-3 input-group has-validation">
                                            <input onChange={(event) => handleValidate(event.target.value, event, 'nohp', 'wajib')} id="nohp" type="text" className="form-control" aria-describedby="nohp" placeholder='Lengkapi dengan nomor Hpmu ..' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='btn btn-success' onClick={handleSimpan}>
                            Simpan
                        </div>
                    </Modal.Footer>
                </Modal>
            
        </div>
        
    )
};

export default Lihat;