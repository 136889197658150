import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
            useReactTable,
            flexRender,
            getCoreRowModel,
            getPaginationRowModel,
            getFilteredRowModel,
        } from '@tanstack/react-table';

import { rankItem } from "@tanstack/match-sorter-utils";
import { Context } from "./services";

const Pagination = ({ pageCount, onPageChange, initPage }) => {
    return (
        <ReactPaginate
            previousLabel={<i className="fa-solid fa-caret-left"></i>}
            nextLabel={<i className="fa-solid fa-caret-right"></i>}
            breakLabel={<i className="fa-solid fa-ellipsis"></i>}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            initialPage={initPage}
        />
    );
};

export const Table = ({ columns, data , columnVis, statVis}) => {
    const [pagiNation, setPagi] = useContext(Context);
    const [pagination, setPagination] = useState({
        pageIndex: pagiNation,
        pageSize: 3,
    });

    const [columnVisibility, setColumnVisibility] = useState(columnVis);

    useEffect(()=>{
        let tmpdata = {...columnVisibility};
        switch(statVis){
            case 1:
                tmpdata.status = true;
                tmpdata.aksi = true;
            break;
            case 2:
                tmpdata.status = false;
                tmpdata.aksi = false;
                tmpdata.aksi2 = false;
            break;
            default:
                tmpdata.status = false;
                tmpdata.aksi = true;
        }
        setColumnVisibility(tmpdata);
        // eslint-disable-next-line
    }, [statVis]);

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({
            itemRank,
        })
        console.log(itemRank);
        return itemRank.passed;
    };

    const [globalFilter, setGlobalFilter] = useState('');
    
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        onColumnVisibilityChange: setColumnVisibility,
        globalFilterFn: 'fuzzy',
        state: {
            pagination,
            globalFilter,
            columnVisibility,
        },
        filterFns:{
            fuzzy: fuzzyFilter,
        },
    });

    const handlePageChange = (selectedPage) => {
        table.setPageIndex(selectedPage.selected);
        setPagi(selectedPage.selected);
    };

    useEffect(()=>{
        table.setPageIndex(pagiNation);
        // eslint-disable-next-line
    }, [data]);
  
    return (
        <>  
            <input
                value={globalFilter ?? ''}
                className="hidethis"
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search all columns..."
                style={{ marginBottom: '10px' }}
            />
            {data.length > 0 ? (
                <>
                    <table className="main-table table table-striped table-hover">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th className="text-center" key={header.id}>
                                            {header.isPlaceholder ? null : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td className={cell.column.id === 'nomor_perkara' ? 'bold-cell' : null} key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                        <Pagination pageCount={table.getPageCount()} onPageChange={handlePageChange} initPage={pagiNation} />
                    </div>
                    { /*<button onClick={()=>console.log(data)}>cek</button>*/ }
                </>
            ) : (
                <div className="d-flex justify-content-center">
                    <h6>Tidak ada data <i className="fa-solid fa-file-circle-question fa-bounce"></i></h6>
                </div>
            )}
        </>
    );
};

export const TableCetak = ({ columns, data }) => {
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel()
    });

    return(
        <>
            <table className="main-table table table-striped table-hover fs-10">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            <th>No.</th>
                            {headerGroup.headers.map(header => (
                                <th className="text-center" key={header.id}>
                                    {header.isPlaceholder ? null : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row,rowIndex) => (
                        <tr key={row.id}>
                            <td>{rowIndex + 1}.</td>
                            {row.getVisibleCells().map(cell => (
                                <td className={cell.id} key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export const TableDetail = ({columns, data})=>{
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel()
    });

    return(
        <>
            <table className="main-table table table-striped table-hover fs-10">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th className="text-center" key={header.id}>
                                    {header.isPlaceholder ? null : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row,rowIndex) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td className={cell.id === 'nomor_perkara' ? 'bold-cell' : null} key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export const TableRank = ({columns, data, dataTwo})=>{
    const table = useReactTable({
        columns,
        data: data,
        getCoreRowModel: getCoreRowModel(),
    });

    const tableTwo = useReactTable({
        columns,
        data: dataTwo,
        getCoreRowModel: getCoreRowModel(),
    });

    const rowJenis = (stat) =>{
        let output;
        switch(stat){
            case '0':
                output = 'table-secondary';
            break;
            case '1':
                output = 'table-primary';
            break;
            case '2':
                output = 'table-success';
            break;
            default:
                output = 'kosong';
        }
        return output;
    };
    
    return(
        <>
            {data.length > 0 && dataTwo ? (
                <>
                    <table className="table-rank table table-borderless mt-3">
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr 
                                    key={row.id} 
                                    className={rowJenis(row.original.status)+` mb-2`}>

                                    {row.getVisibleCells().map(cell => (
                                        <td className="text-center" key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className="table-rank table table-borderless mt-3">
                        <tbody>
                            {tableTwo.getRowModel().rows.map(row => (
                                <tr 
                                    key={row.id} 
                                    className={rowJenis(row.original.status)+` mb-2`}>

                                    {row.getVisibleCells().map(cell => (
                                        <td className="text-center" key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="d-flex justify-content-center">
                    <h6>Tidak ada sidang ..<i className="fa-solid fa-file-circle-question fa-bounce"></i></h6>
                </div>
            )}
        </>
    );
};
